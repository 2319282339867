import {getForReport} from '../lib/request';

export const dashboardTotalEarning = (start_time, end_time, group_by, currency, source) => {
  let URL = `/analytics/dashboard/total-earning?group_by=${group_by}&source=${source}&currency=${currency}`;

  if (start_time) {
    URL += `&start_timestamp=${start_time}`;
  }
  if (end_time) {
    URL += `&end_timestamp=${end_time}`;
  }

  return getForReport(URL);
};

export const dashboardTopNPosts = (start_time, end_time, group_by, currency, source) => {
  let URL = `/analytics/dashboard/top-n-posts?group_by=${group_by}&currency=${currency}&source=${source}`;

  if (start_time) {
    URL += `&start_timestamp=${start_time}`;
  }
  if (end_time) {
    URL += `&end_timestamp=${end_time}`;
  }

  return getForReport(URL);
};

export const dashboardTopNSubscription = (start_time, end_time, group_by, currency, source) => {
  let URL = `/analytics/dashboard/top-n-subscriptions?group_by=${group_by}&currency=${currency}&source=${source}`;

  if (start_time) {
    URL += `&start_timestamp=${start_time}`;
  }
  if (end_time) {
    URL += `&end_timestamp=${end_time}`;
  }

  return getForReport(URL);
};

export const dashboardTopCreators = (start_time, end_time, group_by, currency, source) => {
  let URL = `/analytics/dashboard/top-creators?group_by=${group_by}&currency=${currency}&source=${source}`;

  if (start_time) {
    URL += `&start_timestamp=${start_time}`;
  }
  if (end_time) {
    URL += `&end_timestamp=${end_time}`;
  }

  return getForReport(URL);
};
