import getSymbolFromCurrency from 'currency-symbol-map';
import {createBrowserHistory} from 'history';
import jstz from 'jstz';
import ls from 'local-storage';
import moment from 'moment';

import {getCookie, removeCookie} from './session';

let history = createBrowserHistory();

export const logout = () => {
  removeCookie('accessExpireAt');
  removeCookie('token');
  ls.remove('token');
  history.push('/#/');
};

export const goToLogin = (route) => {
  if (getCookie('token') < Date.now()) {
    // ...
  } else {
    route.push('/');
    logout();
  }
};

export const goToDashbord = (route) => {
  // if (getCookie("token") && getCookie("accessExpireAt") < Date.now()) {
  if (getCookie('token') && getCookie('accessExpireAt')) {
    route.push('/dashbord/fanzly-analytics-overview');
  } else {
    logout();
  }
};

export const convertDate = (date) => {
  return moment(date).format("Do MMM'YYYY hh:mm a") === 'Invalid date'
    ? ''
    : moment(date).format("Do MMM'YYYY hh:mm a");
};

export const convertUnix = (date) => {
  return moment.unix(date).format("Do MMM'YYYY hh:mm a") === 'Invalid date'
    ? ''
    : moment.unix(date).format("Do MMM'YYYY hh:mm a");
};

export const replaceBlankWithDash = (data) => {
  return data === '' ? '-' : data;
};

export const getTimeZone = () => {
  var timezone = jstz.determine();
  let yourTimeZone = timezone.name();

  return yourTimeZone;
};

export const getCurrencySymbol = (currency) => {
  let symbol = getSymbolFromCurrency(currency);

  return symbol;
};

export const tooltipFormatter = (value, currency, isSymbolRequired) => {
  let symbol = '';

  if (isSymbolRequired) {
    symbol = getCurrencySymbol(currency);
  }
  if (value >= 10000 && value < 1000000) {
    let kValue = value / 1000;

    return `${isSymbolRequired ? symbol : ''} ${Number(kValue.toFixed(2))} K`;
  }
  if (value >= 1000000 && value < 1000000000) {
    let kValue = value / 1000000;

    return `${isSymbolRequired ? symbol : ''} ${Number(kValue.toFixed(2))} M`;
  }

  if (value >= 1000000000 && value < 1000000000000) {
    let kValue = value / 1000000000;

    return `${isSymbolRequired ? symbol : ''} ${Number(kValue.toFixed(2))} B`;
  }

  if (value >= 1000000000000) {
    let kValue = value / 1000000000000;

    return `${isSymbolRequired ? symbol : ''} ${Number(kValue.toFixed(2))} T`;
  }

  return isFloat(value)
    ? `${isSymbolRequired ? symbol : ''} ${Number(value.toFixed(2))}`
    : `${isSymbolRequired ? symbol : ''} ${value}`;
};

export const isFloat = (n) => Number(n) === n && n % 1 !== 0;

export const setInLocalStorage = (key, value) => {
  if (!key || !value) {
    return false;
  }
  ls.set(key, value);
  return true;
};

export const getFromLocalStorage = (key) => ls.get(key);

export const removeFromLocalStorage = (key) => ls.remove(key);

export const dateTimeFormatForGraph = (formatType, data) => {
  let format = {
    Hour: 'll H:00',
    Day: 'll',
    Week: 'll',
    'Hour Of Day': 'HH:00',
  };
  let xCategories;

  if (formatType === 'Hour') {
    xCategories = data.map((dateTime) => {
      return moment(dateTime).format(format[formatType]);
    });
  } else if (formatType === 'Day' || formatType === 'Week' || formatType === 'Hour Of Day') {
    xCategories = data.map((dateTime) => {
      return moment(dateTime).format(format[formatType]);
    });
  } else {
    xCategories = data;
  }
  return xCategories;
};
