import LOGO from '../static/images/app_images/appscrip.png';
import AppLogo from '../static/images/app_logo/main_logo.png';
import BackArrow from '../static/images/assets/back.png';
import LogOut from '../static/images/assets/logout.png';
import Message from '../static/images/assets/message.png';
import WEBP_1 from '../static/images/dummy/1.webp';

export const MESSAGE = Message;
export const APP_NAME = 'Appscrip';
export const MAIN_LOGO = LOGO;
export const WEBP_DUMMY_1 = WEBP_1;

// OG DATA VARS
export const OG_IMAGE = 'https://www.appscrip.com/wp-content/uploads/2019/03/Group-4612.png'; // ???

//app logo
export const APP_LOGO = AppLogo;
export const LOGOUT = LogOut;

export const PRIMARY = '#3a3abf';
export const LIGHT_PRIMARY = '#8181ec';
export const SHADOW =
  'box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px #8181ec !important;';

export const BACK_ARROW = BackArrow;
