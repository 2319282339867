import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';

export default function ReactSelect(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [select, setSelect] = React.useState(props.selected);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget || null);
  };

  const handleChange = (event) => {
    const {items} = props;

    setAnchorEl(null);
    setSelect(items[event] || select);
    return props.handleChange(items[event] || select);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="outlined"
        onClick={handleClick}
      >
        {select.label || 'Open Menu'}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleChange}
      >
        {props.items.map((data, index) => (
          <MenuItem onClick={() => handleChange(index)} key={index}>
            {data.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

ReactSelect.propTypes = {
  selected: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
};
