import {post} from './request';

export let getNewAccessToken = async (accessToken, refreshToken) => {
  const data = await post('/v1/generateToken', {
    headers: {
      'Content-Type': 'application/json',
      language: 'en',
      lan: 'en',
      platform: 3,
      currencySymbol: '$',
      currencyCode: 'USD',
    },
    body: {
      refToken: accessToken,
      accessToken: refreshToken,
    },
  });

  return data;
};
